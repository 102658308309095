<template>
  <AcaoItem
    titulo="Classificação"
    :color="color"
    :historico="historico.historicosClassificacao"
  >
    <template v-slot:default>
      <p>
        Classifica o processo de acordo com as regras estabelecidas no edital.
      </p>

      <div v-if="processo.tipoProcesso.usaNotaEnem && processo.podeFazerAcoes">
        <v-form ref="parametrosClassificaoForm">
          <div
            ref="notasDeCorteEnemDiv"
            v-if="
              processo.tipoProcesso.usaNotaEnem &&
              ['IVLIB', 'IVTHE'].includes(processo.tipoProcesso.codigo)
            "
          >
            <p>
              Preencha os valores de nota de corte. Os candidatos com nota
              inferior a nota de corte serão eliminados.
            </p>
            <v-row dense align="center">
              <v-col class="d-flex" cols="6">
                <v-auto-numeric
                  ref="notaDeCorteLinguagens"
                  v-model.number="classificacaoParams.notaDeCorteLinguagens"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Linguagens *"
                  name="notaDeCorteLinguagens"
                  outlined
                  validate-on-blur
                ></v-auto-numeric>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-auto-numeric
                  ref="notaDeCorteCienciasHumanas"
                  v-model.number="
                    classificacaoParams.notaDeCorteCienciasHumanas
                  "
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Ciências Humanas *"
                  name="notaDeCorteCienciasHumanas"
                  outlined
                  validate-on-blur
                ></v-auto-numeric>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-auto-numeric
                  ref="notaDeCorteCienciasNatureza"
                  v-model.number="
                    classificacaoParams.notaDeCorteCienciasNatureza
                  "
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Ciências da Natureza *"
                  name="notaDeCorteCienciasNatureza"
                  outlined
                  validate-on-blur
                ></v-auto-numeric>
              </v-col>
              <v-col class="d-flex" cols="6">
                <v-auto-numeric
                  ref="notaDeCorteMatematica"
                  v-model.number="classificacaoParams.notaDeCorteMatematica"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Matemática *"
                  name="notaDeCorteMatematica"
                  outlined
                  validate-on-blur
                ></v-auto-numeric>
              </v-col>
              <v-col class="d-flex" cols="12">
                <v-auto-numeric
                  ref="notaDeCorteRedacao"
                  v-model.number="classificacaoParams.notaDeCorteRedacao"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Redação *"
                  name="notaDeCorteRedacao"
                  outlined
                  validate-on-blur
                ></v-auto-numeric>
              </v-col>
            </v-row>
          </div>
          <div
            ref="notaDeCorteTotalDiv"
            v-if="processo.tipoProcesso.usaNotaEnem &&
                 ['TEE'].includes(processo.tipoProcesso.codigo)"
          >
            <v-auto-numeric
              v-if="
                processo.tipoProcesso.codigo === 'TEE' &&
                classificacaoParams.situacaoInscricaoAClassificar == 'ATV'
              "
              ref="notaDeCorteTotal"
              v-model.number="classificacaoParams.notaDeCorteTotal"
              :rules="[campoObrigatorioRule]"
              dense
              label="Nota Total *"
              name="notaDeCorteTotal"
              outlined
              validate-on-blur
            ></v-auto-numeric>
            <v-select
              v-model="classificacaoParams.situacaoInscricaoAClassificar"
              dense
              :items="[
                { nome: 'Ativos', codigo: 'ATV' },
                { nome: 'Aptos', codigo: 'APT' },
              ]"
              item-text="nome"
              item-value="codigo"
              label="Situação da inscrição para classificar"
              name="situacaoInscricaoAClassificar"
              outlined
            >
            </v-select>
          </div>
        </v-form>
      </div>
    </template>
    <template v-slot:botao>
      <v-btn
        class="mt-1"
        :disabled="!processo.podeFazerAcoes"
        color="primary"
        outlined
        @click="classificar"
      >
        Classificar
      </v-btn>
      <v-btn
        class="mt-1 ml-2"
        :disabled="!historico.historicosClassificacao"
        color="accent"
        outlined
        @click="visualizarClassificacao"
      >
        Visualizar
      </v-btn>
    </template>
    <template v-slot:extra>
      <v-dialog v-model="dialogResultado" width="600">
        <v-card key="resultado" v-if="dialogResultado">
          <v-card-title class="primary white--text text-h6 justify-center mb-2">
            Resultado Classificação
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <v-alert :type="resultado.tipo" text outlined dense>
              {{ resultado.msg }}
            </v-alert>
            <v-sheet v-if="resultado.detalhes" outlined rounded>
              <v-virtual-scroll
                :items="resultado.detalhes"
                :item-height="25"
                height="400"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-sheet>
          </v-card-text>
          <div
            class="d-flex align-center justify-center mb-4"
            v-if="resultado.codigo === 'CL02'"
          >
            <v-btn outlined color="error" @click="dialogIndeferido = true">
              <v-icon left>mdi-account-multiple-remove-outline</v-icon>
              Eliminar candidatos sem nota durante a classificação</v-btn
            >
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogResultado = false">
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <template>
        <v-dialog v-model="dialogIndeferido" width="400">
          <v-card>
            <v-feito-title
              class="primary white--text text-h6 justify-center mb-2"
            >
              Confirmar Ação
            </v-feito-title>
            <div class="d-flex flex-column">
              <span class="pa-5">
                Esta ação elimina todos os candidatos listados sem notas enem
                cadastradas, deseja confirmar?
              </span>
              <v-divider />
              <div
                class="d-flex align-center justify-center mb-4 mt-5"
                style="gap: 1em"
              >
                <v-btn color="primary" @click="eliminaCandidatosSemNotaEnem"
                  >Sim</v-btn
                >
                <v-btn color="error" @click="dialogIndeferido = false"
                  >Não</v-btn
                >
              </div>
            </div>
          </v-card>
        </v-dialog>
      </template>
    </template>
  </AcaoItem>
</template>

<script>
import rules from '@/commons/rules';
import { mapActions } from 'vuex';
import AcaoItem from './AcaoItem';
import classificacaoService from '@/services/classificacao.service';

export default {
  name: 'ClassificacaoItem',
  components: { AcaoItem },
  props: {
    color: { type: String, required: true },
    processo: { type: Object, required: true },
    historico: { type: Object, required: true },
  },

  data() {
    return {
      dialogIndeferido: false,
      dialogResultado: false,
      indeferido: false,
      classificacaoParams: {
        notaDeCorteLinguagens: 0.01,
        notaDeCorteCienciasHumanas: 0.01,
        notaDeCorteCienciasNatureza: 0.01,
        notaDeCorteMatematica: 0.01,
        notaDeCorteRedacao: 0.01,
        notaDeCorteTotal: 0.01,
        situacaoInscricaoAClassificar: 'ATV',
      },
      eliminadosSemNota: 0,
      resultado: {},
    };
  },

  methods: {
    ...rules,
    ...mapActions('avisos', ['exibirAviso', 'esconderAviso']),

    async classificar() {
      if (
        !this.$refs.parametrosClassificaoForm ||
        this.$refs.parametrosClassificaoForm.validate()
      ) {
        let loader = this.$loading.show();
        await classificacaoService
          .classificar(this.processo.oid, this.classificacaoParams)
          .then((response) => {
            this.$emit('feito');
            this.resultado = {
              tipo: 'success',
              msg: 'Classificação realizada com sucesso',
              detalhes: [
                `Classificados: ${response.data.qtdClassificados}`,
                this.classificacaoParams.situacaoInscricaoAClassificar == 'ATV'
                  ? `Eliminados: ${response.data.qtdEliminados}`
                  : '',
              ],
            };
            if (this.eliminadosSemNota !== 0) {
              this.resultado.detalhes.push(
                `Eliminados sem Nota: ${this.eliminadosSemNota}`
              );
            }
          })
          .catch((error) => {
            if (error.response.status === 409) {
              this.resultado = {
                tipo: 'error',
                msg: error.response.data.msg,
                detalhes: error.response.data.detalhes,
                codigo: error.response.data.codigoErro ?? null,
              };
            }
          });

        this.dialogResultado = true;

        loader.hide();
      }
    },

    visualizarClassificacao() {
      this.$router.push({
        name: 'ClassificacaoFiltro',
        params: { processoOid: this.processo.oid },
      });
    },
    async eliminaCandidatosSemNotaEnem() {
      if (
        !this.$refs.parametrosClassificaoForm ||
        this.$refs.parametrosClassificaoForm.validate()
      ) {
        let loader = this.$loading.show();
        await classificacaoService
          .eliminarCandidatosSemNotaEnem(
            this.processo.oid,
            this.classificacaoParams
          )
          .then((response) => {
            this.eliminadosSemNota = response.data;
            this.exibirAviso({
              mensagem: 'Candidatos eliminados com sucesso!',
              tipo: 'success',
            });
          })
          .catch((error) => {
            let mensagem = 'Atenção';

            if (error.response.status === 409) {
              mensagem += ': ' + error.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: 'warning',
            });
          });

        this.exibirAviso({
          mensagem: 'Classificando...',
          tipo: 'warning',
        });
        loader.hide();
        this.classificar().then(() => this.esconderAviso());
        this.dialogIndeferido = false;
      }
    },
  },
};
</script>

<style scoped></style>
